<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-text-field
          :label="$i18n.locale == 'en' ? 'Search for items' : 'ابحث عن اصناف'"
          prepend-inner-icon="mdi-magnify"
          flat
          filled
          v-model="mixedNameCode"
          @change="searchName"
          outlined
          dense
          solo
        ></v-text-field>
      </v-col>
      <!-- <v-col cols="12">
        <v-chip-group>
          <v-chip
            v-for="chip in filtersChips"
            :key="chip"
            outlined
            @click="removeFilter(chip)"
          >
            <v-icon size="20" class="mr-3">mdi-close-circle-outline</v-icon>
            {{ chip.name }}
          </v-chip>
        </v-chip-group>
      </v-col> -->
    </v-row>
    <v-row>
      <v-col v-if="width < 960"
        ><v-btn
          @click="sheet = !sheet"
          outlined
          block
          elevation="0"
          style="background: white; letter-spacing: 0"
          tile
          ><v-icon>mdi-filter</v-icon> {{ $t("itemsPage.filter") }}</v-btn
        >
        <v-bottom-sheet v-model="sheet" fullscreen scrollable>
          <v-card class="pa-3">
            <v-card-title
              ><v-btn block @click="sheet = !sheet" text style="letter-spacing: 0"
                ><v-icon>mdi-filter</v-icon> {{ $t("itemsPage.filter")
                }}<v-icon>mdi-window-close</v-icon></v-btn
              ></v-card-title
            >
            <v-card-text>
              <side-bar
                @clearFilters="handleClearFilters"
                @update="getItems"
                ref="filtersBar"
              />
            </v-card-text>
            <v-card-actions
              ><v-btn
                style="letter-spacing: 0"
                block
                @click="sheet = !sheet"
                color="black"
                dark
                >{{ $t("itemsPage.showResults") }}</v-btn
              ></v-card-actions
            >
          </v-card>
        </v-bottom-sheet>
      </v-col>

      <!-- TopBar -->
      <v-col cols="12" sm="12" md="8">
        <top-bar
          :meta="meta"
          @filterLimit="handleLimitChange"
          @filterSort="handleSortChange"
          @gridTypeChange="handleGridChange"
          :screenWidth="width"
        />
        <!-- Items -->
        <div v-if="!itemsLoading && items.length > 0">
          <v-container>
            <v-layout row wrap>
              <v-row>
                <v-col
                  v-for="item in items"
                  :key="item.title"
                  cols="12"
                  sm="6"
                  :md="gridType"
                >
                  <item-card :item="item" />
                </v-col>
              </v-row>
            </v-layout>
          </v-container>
          <div class="text-center pt-2">
            <v-pagination
              v-model="page"
              :length="meta.lastPage"
              @input="changePage"
              color="black"
            ></v-pagination>
          </div>
        </div>
        <!-- Loader -->
        <div v-if="itemsLoading" class="mt-16 pt-16">
          <v-row
            ><v-progress-circular
              indeterminate
              class="mx-auto"
              size="100"
            ></v-progress-circular
          ></v-row>
        </div>
        <!-- No content -->
        <no-content
          v-if="!itemsLoading && items.length == 0"
          :title="$t('noContent.title')"
          :msg="$t('noContent.search')"
          icon="mdi-robot-confused"
        />
      </v-col>
      <!-- SideBar -->
      <v-col cols="3" v-if="width >= 960" md="4">
        <side-bar
          @clearFilters="handleClearFilters"
          @update="getItems"
          ref="filtersBar"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Items",
  components: {
    TopBar: () => import("./Components/TopBar.vue"),
    SideBar: () => import("./Components/SideBar.vue"),
    ItemCard: () => import("./Components/ItemCard.vue"),
    NoContent: () => import("../../components/NoContent.vue"),
  },
  data() {
    return {
      mixedNameCode: "",
      filtersChips: [],
      page: 1,
      itemsLoading: false,
      gridType: 4,
      searchVal: "",
      sheet: false,
      sorts: {
        sortByPriceAsc: false,
        sortByPriceDesc: false,
        sortByMostViews: false,
      },
    };
  },
  computed: {
    ...mapState({
      items: (state) => state.itemsModule.items,
      meta: (state) => state.itemsModule.meta,
      filters: (state) => state.itemsModule.filters,
    }),
    width() {
      return this.$vuetify.breakpoint.width;
    },
  },
  mounted() {
    if (this.filters.page) this.page = this.filters.page;
  },
  created() {
    this.getItems();

    // window.addEventListener("resize", this.windowResizeHandler);
  },
  methods: {
    changePage() {
      this.$store.commit("itemsModule/SET_FILTERS", { ...this.filters, page: this.page });
      this.getItems(true);
    },
    searchName() {
      this.$store.commit("itemsModule/SET_FILTERS", {
        ...this.filters,
        mixedNameCode: this.mixedNameCode,
      });
      this.getItems();
    },
    getItems(paginating) {
      if (paginating !== true) {
        this.page = 1;
      }

      this.itemsLoading = true;
      this.$store
        .dispatch("itemsModule/getItems", {
          filters: {
            page: this.filters.page,
            limit: this.filters.limit,
            fromPrice: this.filters.fromPrice,
            toPrice: this.filters.toPrice,
            isAvailable: this.filters.isAvailable,
            sortByPriceAsc: this.filters.sorts?.sortByPriceAsc,
            sortByPriceDesc: this.filters.sorts?.sortByPriceDesc,
            sortByMostViews: this.filters.sorts?.sortByMostViews,
            texture: this.filters.texture?.toString(),
            categoryIds: this.filters.categoryIds?.toString(),
            dimension: this.filters.dimension?.toString(),
            finishing: this.filters.finishing?.toString(),
            mixedNameCode: this.filters.mixedNameCode?.trim() ?? '',
          },
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          this.itemsLoading = false;
          // window.scrollTo({ top: 0, behavior: "smooth" });
          if (paginating === true) {
            window.scrollTo({ top: 0, behavior: "smooth" });
          }
        });
    },

    handleSortChange(val) {
      if (val == "Higher price" || val == "السعر الاعلى اولا") {
        this.sorts.sortByPriceDesc = true;
        this.sorts.sortByPriceAsc = false;
        this.sorts.sortByMostViews = false;
      } else if (val == "Lower price" || val == "السعر الاقل اولا") {
        this.sorts.sortByPriceAsc = true;
        this.sorts.sortByPriceDesc = false;
        this.sorts.sortByMostViews = false;
      } else if (val == "Most views" || val == "الاكثر عرض") {
        this.sorts.sortByMostViews = true;
        this.sorts.sortByPriceAsc = false;
        this.sorts.sortByPriceDesc = false;
      } else {
        this.sorts.sortByMostViews = false;
        this.sorts.sortByPriceAsc = false;
        this.sorts.sortByPriceDesc = false;
      }
      this.$store.commit("itemsModule/SET_FILTERS", {
        ...this.filters,
        sorts: this.sorts,
      });

      this.getItems();
    },

    handleLimitChange(val) {
      this.$store.commit("itemsModule/SET_FILTERS", { ...this.filters, limit: val });
      this.getItems();
    },
    removeFilter(val) {
      this.$refs.filtersBar.removeFilter(val);
    },
    handleGridChange(val) {
      if (val == "small") this.changeGridToSmall();
      if (val == "large") this.changeGridToBig();
    },
    changeGridToBig() {
      this.gridType = 6;
    },
    changeGridToSmall() {
      this.gridType = 4;
    },
    handleClearFilters() {
      this.filters = {};
      this.getItems();
    },
  },
};
</script>
