<template>
  <div class="pa-5">
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-row dense class="align-center">
          <h1 class="headline font-weight-bold">
            {{ baseItemName() }}
          </h1>
          <v-spacer></v-spacer>
          <v-card flat dark class="green pa-1 px-3" v-if="item.isAvailable">{{
            $t("item.available")
          }}</v-card>
          <v-card flat class="red pa-1 font-weight-medium" v-else dark>{{
            $t("item.unavailable")
          }}</v-card>
        </v-row>
        <v-row>
          <div class="pt-5 pl-2" style="color: green">
            <b>{{ baseItemPrice() }} {{ $t("item.currency") }}/{{
              $i18n.locale == "ar" ? uomArName() : uomName()
            }}</b>
          </div>
        </v-row>
        <v-row dense class="pt-4">
          <v-col cols="4">
            <div>
              <b>{{ $t("item.code") }}</b>
            </div>
            <div>
              <b>{{ $t("item.category") }}</b>
            </div>
            <div>
              <b>{{ $t("item.texture") }}</b>
            </div>
            <div>
              <b>{{ $t("item.finishing") }}</b>
            </div>
            <div>
              <b>{{ $t("item.dimension") }}</b>
            </div>
          </v-col>
          <v-col cols="auto">
            <div>
              <div>
                <b>{{ baseItemCode() }}</b>
              </div>
              <div>
                <b>{{ handleNullProperty(item.category) }}</b>
              </div>
              <div>
                <b>{{ handleNullProperty(item.texture) }}</b>
              </div>
              <div>
                <b>{{ handleNullProperty(item.finishing) }}</b>
              </div>
              <div>
                <b>{{ handleNullProperty(item.dimension) }}</b>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="9">
            <v-btn style="letter-spacing: 0px" v-if="!item.isInCart" @click="addToCart" :loading="cartLoading" block large
              elevation="0" color="blue" dark>{{ $t("item.addToCart") }}</v-btn>
            <v-btn style="letter-spacing: 0px" v-else block large elevation="0" color="grey" dark to="/cart">{{
              $t("item.addeddToCart") }}</v-btn>
          </v-col>
          <v-col cols="3">
            <v-btn @click="showAddToCollectionDialog = true" :loading="favoritesLoading" block large elevation="0"
              color="blue" outlined>
              <v-icon color="blue" v-if="!item.isInFavorites">mdi-heart-outline</v-icon>
              <v-icon color="blue" v-else>mdi-heart</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12">
            <v-btn dark block style="letter-spacing: 0px" elevation="0" :disabled="item.files.length < 1"
              @click="handleFileDownload">{{ $t("item.download") }}
              <v-icon class="mx-2">mdi-tray-arrow-down </v-icon></v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="6" class="pt-2">
        <v-carousel hide-delimiter-background :show-arrows="images.length > 1" style="border-radius: 10px"
          :reverse="$i18n.locale == 'ar' ? true : false" continuous cycle height="auto">
          <v-carousel-item v-for="(image, i) in images" :key="i"
            :src="`https://majaal-system-files.ams3.cdn.digitaloceanspaces.com/${image.url}`"></v-carousel-item>
        </v-carousel>

        <!-- <inner-image-zoom :src="images.normal_size[0].url" :zoomSrc="images.normal_size[0].url" /> -->
      </v-col>
    </v-row>
    <browse class="pt-9" :items="similar" v-if="similar.length > 0" />
    <files-dialog :dialog="showFilesDialog" @close="showFilesDialog = false" @download="downloadFile($event)"
      :files="item.files" />
    <add-collection v-if="showAddToCollectionDialog" :dialog="showAddToCollectionDialog"
      @close="showAddToCollectionDialog = false" @done="getItem()" :item="item" />
  </div>
</template>

<script>
import { mapState } from "vuex";
// import 'vue-inner-image-zoom/lib/vue-inner-image-zoom.css';
// import InnerImageZoom from 'vue-inner-image-zoom';

export default {
  components: {
    Browse: () => import("./Browse.vue"),
    FilesDialog: () => import("./FilesDialog.vue"),
    AddCollection: () => import("./AddToCollection.vue"),
    // 'inner-image-zoom': InnerImageZoom
  },
  props: ["id"],
  data() {
    return {

      favoritesLoading: false,
      cartLoading: false,
      showFilesDialog: false,
      downloadFileLoading: false,
      showAddToCollectionDialog: false,
      // images: [],
      zoomerOptions: {
        zoomFactor: 3, // scale for zoomer
        pane: 'pane', // three type of pane ['pane', 'container-round', 'container']
        hoverDelay: 300, // how long after the zoomer take effect
        move_by_click: false, // move image by click thumb image or by mouseover
        choosed_thumb_border_color: "#000000", // choosed thumb border color
        scroller_button_style: "line",
        scroller_position: "bottom",
        zoomer_pane_position: "right"
      },
      images: {},
    };
  },
  computed: {
    ...mapState({
      item: (state) => state.itemsModule.item.item,
      similar: (state) => state.itemsModule.similar,
      linkData: (state) => state.itemsModule.downloadLink,
    }),
  },

  created() {
    this.getItem();
  },
  methods: {
    baseItemCode() {
      return this.item.baseItem?.code
    },
    baseItemName() {
      return this.item.baseItem?.name
    },
    baseItemPrice() {
      return this.item.baseItem?.price
    },
    uomArName() {
      return this.item.uom?.name
    },
    uomName() {
      return this.item.uom?.arName
    },
    handleNullProperty(val) {
      if (val == null) return "N/A";
      else if (!val.name) return val
      else return val.name;
    },
    // addToFavorites() {
    // this.favoritesLoading = true;
    // this.$store
    //   .dispatch("itemsModule/addToFavorites", { id: this.id })
    //   .then(() => {
    //     this.$buefy.toast.open({
    //       message: this.$t("itemsPage.addItemNotif"),
    //       type: "is-success",
    //     });
    //     this.getItem();
    //     this.getFavCount();
    //   })
    //   .catch(() => {
    //     this.$buefy.toast.open({
    //       message: this.$t("app.errorNotif"),
    //       type: "is-danger",
    //     });
    //   })
    //   .finally(() => {
    //     this.favoritesLoading = false;
    //   });
    // },
    // removeFromFavorites() {
    //   this.favoritesLoading = true;
    //   this.$store
    //     .dispatch("itemsModule/removeFromFavorites", { id: this.id })
    //     .then(() => {
    //       this.$buefy.toast.open({
    //         message: this.$t("itemsPage.removeItemNotif"),
    //         type: "is-info",
    //       });
    //       this.getItem();
    //       this.getFavCount();
    //     })
    //     .catch(() => {
    //       this.$buefy.toast.open({
    //         message: this.$t("app.errorNotif"),
    //         type: "is-danger",
    //       });
    //     })
    //     .finally(() => {
    //       this.favoritesLoading = false;
    //     });
    // },
    getFavCount() {
      this.$store.dispatch("favoritesModule/getCount", {});
    },
    downloadFile(file) {
      this.downloadFileLoading = true;

      this.$store
        .dispatch("itemsModule/downloadFile", {
          id: file,
        })
        .then(() => {
          window.open(this.linkData);
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          this.downloadFileLoading = false;
        });
    },
    addToCart() {
      this.cartLoading = true;
      this.$store
        .dispatch("itemsModule/addToCart", { id: this.id })
        .then(() => {
          this.$buefy.toast.open({
            message: this.$t("itemsPage.addCartItemNotif"),
            type: "is-success",
          });
          this.getItem();
          this.getCount();
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => {
          this.cartLoading = false;
        });
    },
    getCount() {
      this.$store.dispatch("cartModule/getCount", {});
    },
    getItem() {
      this.showAddToCollectionDialog = false;
      this.$store
        .dispatch("itemsModule/getItem", { id: this.id })
        .then(() => {
          this.getSimilar();
          this.images = this.item.images.filter((e) => e.type != "thumbnail")?.map(e => { return { id: e.id, url: this.getImage(e) } });
          if (this.images?.length == 0)
            this.images = this.item.images;
        })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        });
    },
    getImage(image) {
      return `https://majaal-system-files.ams3.cdn.digitaloceanspaces.com/${image.url}`
    },
    getSimilar() {
      this.$store
        .dispatch("itemsModule/getSimilar", { id: this.id })
        .catch(() => {
          this.$buefy.toast.open({
            message: this.$t("app.errorNotif"),
            type: "is-danger",
          });
        })
        .finally(() => { });
    },
    handleFileDownload() {
      if (this.item.files.length > 1) this.showFilesDialog = true;
      else this.downloadFile(this.item.files[0].id);
    },
  },
};
</script>

<style scoped>
b {
  font-size: 1.2rem !important;
}

.image-container {
  position: relative;
}

.zoom-image {
  transition: transform 0.3s ease;
}

.zoom-image:hover {
  transform: scale(1.5);
}
</style>
